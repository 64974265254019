<template>
  <div />
</template>

<!--
<script>
import { watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    watch(
      () => store.getters.isLoggedIn,
      (val, oldval) => {
        if (val && val !== oldval) {
          router.push({ name: 'Me' });
        }
      },
    );
  },
};
</script> -->
